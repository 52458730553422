/* eslint-disable */	
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.	

const awsmobile = {	
    "aws_project_region": "us-east-1",	
    "aws_cognito_identity_pool_id": "us-east-1:0e760b9e-c153-4b9b-a97b-46581c7545ee",	
    "aws_cognito_region": "us-east-1",	
    "aws_user_pools_id": "us-east-1_Q1x6FeHDz",	
    "aws_user_pools_web_client_id": "3of0oknu2p5ttr31b058d5rqj3",	
    "oauth": {},	
    "aws_appsync_graphqlEndpoint": "https://ziwytp2va5fediherlbwif3oii.appsync-api.us-east-1.amazonaws.com/graphql",	
    "aws_appsync_region": "us-east-1",	
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",	
    "aws_user_files_s3_bucket": "nora35759-dev",	
    "aws_user_files_s3_bucket_region": "us-east-1"	
};	


export default awsmobile;
